import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../constants';
import './FlightNumberInput.scss';


const DEFAULT_CLASS = 'flight-number-input';
const INPUT_CLASS = `${DEFAULT_CLASS}__input`;
const ERROR_CLASS = `${DEFAULT_CLASS}__error-message`;
const regexGen = (allowDecimal, allowNegative, maxDecimalPlace) => {
  let regex = /^[0-9\b]*$/;
  if (allowNegative && allowDecimal) {
    regex = '^-?\\d*\\.?\\d';
  } else if (allowNegative) {
    regex = /^-?[0-9\b]*$/;
  } else if (allowDecimal) {
    regex = '^\\d*\\.?\\d';
  }
  return (allowDecimal && maxDecimalPlace
    ? new RegExp(`${regex}{0,${parseInt(maxDecimalPlace, 10)}}$`) : regex);
};

const FlightNumberInput = (props) => {
  const {
    className, disabled, errorMessage, hasError, inputRef,
    label, placeholderText, onLoad, onBlur, allowDecimal,
    allowNegative, maxValue, minValue, onChange, value,
    maxDecimalPlace, width, onKeyDown, onKeyPress,
  } = props;
  const wrapperRef = (element) => {
    if (element) {
      // Resize component width based on this.props.width
      /* eslint-disable no-param-reassign */
      element.style.width = width;
    }
  };
  useEffect(() => {
    if (onLoad) onLoad();
  });
  const handleValueChange = (event) => {
    const re = regexGen(allowDecimal, allowNegative, maxDecimalPlace);
    const parseFunction = allowDecimal ? parseFloat : parseInt;

    if (disabled || !re.test(event.target.value) || event.target.value === '.'
      || parseFunction(event.target.value) > parseFunction(maxValue)
      || parseFunction(event.target.value) < parseFunction(minValue)) return false;

    if (onChange) onChange(event);
    return null;
  };
  const handleKeyDown = (event) => {
    if (disabled) return;
    const target = { ...event.target };
    if (event.key === EVENT_KEYS.ARROW_UP || event.key === EVENT_KEYS.ARROW_DOWN) {
      target.value = ((parseFloat(event.target.value) || 0)
        + (event.key === EVENT_KEYS.ARROW_UP ? 1 : -1)).toString();
      if (allowDecimal && target.value.toString().indexOf('.') !== -1) {
        target.value = parseFloat(parseFloat(target.value).toFixed(
          maxDecimalPlace,
        )).toString();
      }
      handleValueChange({ ...event, target });
      event.preventDefault();
    }
    if (onKeyDown) onKeyDown(event);
  };
  const handleKeyPress = (event) => {
    if (disabled) return;
    if (onKeyPress) onKeyPress(event);
  };
  const handleOnBlur = (event) => {
    if (disabled) return;
    const normalizedValue = (event.target.value.length
      ? parseFloat(event.target.value).toString() : '');
    const target = { ...event.target, value: normalizedValue };
    const newEvent = { ...event, target };

    if (event.target.value !== normalizedValue) {
      handleValueChange(newEvent);
    }
    if (onBlur) onBlur(newEvent);
  };

  let labelWrapperClass = DEFAULT_CLASS;
  labelWrapperClass += hasError ? ` ${DEFAULT_CLASS}--error` : '';
  labelWrapperClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  labelWrapperClass += className ? ` ${className}` : '';

  let inputClass = INPUT_CLASS;
  inputClass += !label ? ` ${INPUT_CLASS}--without-label` : '';
  inputClass += value ? ` ${INPUT_CLASS}--not-empty` : '';

  let errorMessageClass = ERROR_CLASS;
  errorMessageClass += disabled ? ` ${ERROR_CLASS}--disabled` : '';

  return (
    <div className="flight-number-input-wrapper" ref={wrapperRef}>
      <label className={labelWrapperClass} htmlFor="number-input">
        <input
          className={inputClass}
          disabled={disabled}
          onChange={handleValueChange}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
          placeholder={placeholderText}
          ref={inputRef}
          value={value}
        />
        <span className={`${DEFAULT_CLASS}__label`}>{label}</span>
        <span className={`${DEFAULT_CLASS}__border`} />
      </label>
      {hasError && errorMessage && (
        <span className={errorMessageClass}>{errorMessage}</span>
      )}
    </div>
  );
};

FlightNumberInput.propTypes = {
  allowDecimal: PropTypes.bool,
  allowNegative: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  maxDecimalPlace: PropTypes.number,
  maxValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  minValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onLoad: PropTypes.func,
  placeholderText: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  inputRef: PropTypes.shape({
    current: PropTypes.shape({
      focus: PropTypes.func,
    }),
  }),
};

FlightNumberInput.defaultProps = {
  allowDecimal: false,
  allowNegative: false,
  className: '',
  disabled: false,
  errorMessage: '',
  hasError: false,
  inputRef: null,
  label: '',
  maxDecimalPlace: 2,
  maxValue: '',
  minValue: '',
  onBlur: () => undefined,
  onChange: () => undefined,
  onKeyDown: () => undefined,
  onKeyPress: () => undefined,
  onLoad: () => undefined,
  placeholderText: '',
  value: '',
  width: '232px',
};

export default FlightNumberInput;
