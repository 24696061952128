import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../utils/getIcon';
import './FlightTextArea.scss';

const DEFAULT_ERROR_MESSAGE = 'Error field';
const DEFAULT_LABEL_TEXT = 'Description';
const DEFAULT_CLASS = 'flight-text-area';
const DEFAULT_MAX_LENGTH = 500;
const DEFAULT_WIDTH = '400px';

const FlightTextArea = (props) => {
  const {
    className, disabled, errorMessage, hasError, label,
    maxLength, onChange, onFocus, onBlur, width, value,
  } = props;

  const wrapperRef = (element) => {
    if (element) {
      // Resize component width based on this.props.width
      /* eslint-disable no-param-reassign */
      element.style.minWidth = width;
      element.style.width = width;
    }
  };

  const handleValueChange = (event) => {
    if (disabled || event.target.value.length > maxLength) return;
    if (onChange) onChange(event);
  };
  const handleOnFocus = (event) => {
    if (disabled) return;
    if (onFocus) onFocus(event);
  };
  const handleOnBlur = (event) => {
    if (disabled) return;
    if (onBlur) onBlur(event);
  };

  const normalizedValue = value.slice(0, maxLength);
  const iconStatus = hasError ? getIcon(
    'error', { className: `${DEFAULT_CLASS}__status-icon` },
  ) : null;
  const footerText = (hasError
    ? errorMessage : `${normalizedValue.length} / ${maxLength}`);

  let mainClass = DEFAULT_CLASS;
  mainClass += hasError ? ` ${DEFAULT_CLASS}--error` : '';
  mainClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  mainClass += className ? ` ${className}` : '';

  return (
    <div className={mainClass} ref={wrapperRef}>
      <textarea
        className={`${DEFAULT_CLASS}__textarea`}
        disabled={disabled}
        maxLength={maxLength}
        onChange={handleValueChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        value={normalizedValue}
      />
      <span className={`${DEFAULT_CLASS}__span`}>
        {label}
      </span>
      {iconStatus}
      <span className={`${DEFAULT_CLASS}__footer`}>
        {footerText}
      </span>
    </div>
  );
};

FlightTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  width: PropTypes.string,
};

FlightTextArea.defaultProps = {
  className: '',
  disabled: false,
  errorMessage: DEFAULT_ERROR_MESSAGE,
  hasError: false,
  label: DEFAULT_LABEL_TEXT,
  maxLength: DEFAULT_MAX_LENGTH,
  onChange: () => undefined,
  onFocus: () => undefined,
  onBlur: () => undefined,
  value: '',
  width: DEFAULT_WIDTH,
};

export default FlightTextArea;
